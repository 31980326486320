// consumption la phi tieu thu nang luong theo khu vuc T
// emission_factor la he so phat thai
import { evaluate } from 'mathjs'
import i18n from '@/lang/i18n';

const CO2BY_DEVICES = {
  'Aa': {
    default: [1510, 1315, 1134, 1316, 1190, 1119, 985, 937],
    heigher: [1777, 1542, 1325, 1543, 1393, 1308, 1147, 1089],
  },
  'Ab': {
    default: [1492, 1299, 1096, 1242, 1109, 926, 740, 525],
    heigher: [1755, 1523, 1279, 1455, 1295, 1076, 852, 595],
  },
  'Ba': {
    default: [1252, 1176, 1069, 1218, 1080, 1081, 965, 937],
    heigher: [1467, 1376, 1248, 1426, 1260, 1261, 1122, 1089],
  },
  'Bb': {
    default: [1233, 1160, 1031, 1144, 998, 887, 720, 525],
    heigher: [1444, 1357, 1202, 1338, 1163, 1029, 828, 595],
  },
  'Ca': {
    default: [957, 905, 839, 924, 813, 870, 848, 937],
    heigher: [1113, 1051, 972, 1073, 940, 1009, 983, 1089],
  },
  'Cb': {
    default: [939, 889, 801, 850, 732, 677, 603, 525],
    heigher: [1091, 1031, 926, 985, 843, 777, 689, 595],
  }
}

export const get_emission_factor = (device, belVal, facility) => {
  if(!device || !belVal || !facility) return 0
  const belName = belVal > 1 ? 'heigher' : 'default'
  return CO2BY_DEVICES[device][belName][facility - 1]
} 

export const emissionFor1m2 = (consumption, emission_factor) => {
  if(!consumption) return 0
  return evaluate(`((${emission_factor} * 1000 / 9.76 * ${consumption} * 0.51) + (0.0498 * ${consumption} * 0.21) + (0.059 * ${consumption} * 0.10) + (0.0678 * ${consumption} * 0.18)) / 1000`)
}

export const emissionForHourUnit = (emission_factor, belVal = 1) => {
  if (!emission_factor) return 0
  return evaluate(`522 * ${belVal} * ${emission_factor} / 9.76`)
}

export const emissionContinuousOperation = (emission_factor, belVal = 1) => {
  if (!emission_factor) return 0
  return evaluate(`801 * ${belVal} * ${emission_factor} / 9.76`)
}

export const FACILITY_LIST = [1,2,3,4,5,6,7,8]

export const BELS = {
  BEL_HEIGHER: i18n.t('casbee.option_or_higher'),
  BEL: '1.0',
}

export const HEATING_EQUIPMENTS = {
  HOUSE_UNIT_HEATING: i18n.t('casbee.option_heat_the_entire'),
  CONTINUOUS_OPERATION: i18n.t('casbee.option_heat_living_room_continuous'),
  INTERMITTENT_OPERATION: i18n.t('casbee.option_heat_living_room_intermittent')
}

export const COOLING_DEVICES = {
  HOUSE_UNIT_HEATING: i18n.t('casbee.option_cool_the_entire'),
  INTERMITTENT_OPERATION: i18n.t('casbee.option_cool_living_room_intermittent'),
}